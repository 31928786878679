.tooltip {
    position: absolute;
    right: -280px;
    top: 50%;
    transform: translateY(-50%);
    width: 260px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    font-size: 0.9rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1000;

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    .tooltip-content {
        padding: 16px;
    }

    .tooltip-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #eee;
        font-weight: 600;
        color: #374151;

        &.valid {
            color: #22c55e;
        }

        .status-icon {
            display: flex;
            margin-left: 6px;
            align-items: center;
        }

        .success-check {
            color: #22c55e;
        }
    }

    .tooltip-condition {
        font-size: 0.85rem;
        color: #6b7280;
        padding: 8px 0;

        span {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .condition-check {
            color: #22c55e;
        }

        .condition-x {
            color: #ef4444;
        }
    }

    .validation-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 6px 0;
        transition: all 0.3s ease;

        &.valid {
            color: #22c55e;
            transform: translateX(4px);
        }

        .validation-icon {
            display: flex;
            align-items: center;

            .check-icon {
                color: #22c55e;
            }

            .x-icon {
                color: #ef4444;
            }

            .alert-icon {
                color: #f59e0b;
            }
        }

        .validation-label {
            color: #4b5563;
            line-height: 1.4;
        }
    }

    // 애니메이션 효과
    .bounce {
        animation: bounce 0.5s ease;
    }

    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-4px);
        }
        60% {
            transform: translateY(-2px);
        }
    }

    // 조건 만족 시 슬라이드 효과
    @keyframes slideRight {
        from {
            opacity: 0;
            transform: translateX(-10px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .validation-item {
        &.valid {
            animation: slideRight 0.3s ease forwards;
        }
    }

    .tooltip-close {
        position: absolute;
        top: 12px;
        right: 12px;
        background: none;
        border: none;
        color: #9ca3af;
        cursor: pointer;
        padding: 4px;
        font-size: 1.2rem;
        line-height: 1;
        border-radius: 4px;

        &:hover {
            background-color: #f3f4f6;
            color: #4b5563;
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        border-width: 8px;
        border-style: solid;
        border-color: transparent white transparent transparent;
        filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.05));
    }
}
