.card {
    width: 200px;
    height: 300px;
    gap: 2rem;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
        border-color: #fff;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        transition: background 0.3s ease;
    }

    &:hover::before {
        background: rgba(0, 0, 0, 0.4);
    }

    .card-header {
        position: absolute;
        bottom: 1rem;
        width: 170px;
        background: transparent;
        color: #fff;
        padding: 1rem;
        text-align: left;
        transition: transform 0.3s ease;

        h1 {
            margin: 0;
            font-size: 1.5rem;
        }
    }

    .card-content {
        display: none;
        color: #fff;
        padding: 1rem;
        box-sizing: border-box;
        text-align: left;
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        right: 0;
        z-index: 2;
        max-height: 7rem;
        overflow: visible;

        p {
            padding-bottom: 6px;
        }

        .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;

            .tag {
                background-color: rgba(255, 255, 255, 0.8);
                border: none;
                border-radius: 5px;
                padding: 4px 8px;
                margin: 0;
                font-size: 0.8em;
                color: #333;
                cursor: pointer;
                transition:
                    background-color 0.3s ease,
                    transform 0.2s ease;

                &:hover {
                    background-color: #ddd;
                    transform: scale(1.05);
                }
            }
        }
    }

    &:hover .card-header {
        bottom: 15%;
    }

    &:hover .card-content {
        display: block;
    }
}
