.resend-email-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  animation: fadeIn 0.3s ease;
}

.resend-email-modal {
  position: relative;
  width: 100%;
  max-width: 470px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  animation: slideUp 0.4s ease;
  transform: translateX(0);

  .modal-header {
    position: relative;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #f8fafc, #f1f5f9);

    .header-icon {
      color: #0f172a;
    }

    .close-button {
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      color: #64748b;
      padding: 0.5rem;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        background-color: #f1f5f9;
        color: #0f172a;
      }
    }
  }

  .modal-content {
    padding: 1.5rem 2rem 2rem;

    h2 {
      color: #0f172a;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0.75rem;
    }

    .subtitle {
      color: #64748b;
      text-align: center;
      font-size: 0.95rem;
      margin-bottom: 2rem;
    }

    .input-group {
      display: flex;
      gap: 0.75rem;
      margin-bottom: 1.25rem;
      @media (max-width: 480px) {
        flex-direction: column; // 모바일에서는 세로로 배치

        input {
          width: 100%;
        }

        .send-button {
          width: 100%;
          justify-content: center;
        }
      }

      input {
        flex: 1;
        padding: 0.875rem 1rem;
        border: 2px solid #e2e8f0;
        border-radius: 12px;
        font-size: 0.95rem;
        color: #0f172a;
        transition: all 0.2s ease;

        &:hover {
          border-color: #cbd5e1;
        }

        &:focus {
          outline: none;
          border-color: #2563eb;
          box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
        }

        &::placeholder {
          color: #94a3b8;
        }

        &:disabled {
          background-color: #f8fafc;
          cursor: not-allowed;
        }
      }

      .send-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.875rem 1.25rem;
        background-color: #2563eb;
        color: white;
        border: none;
        border-radius: 12px;
        font-weight: 500;
        font-size: 0.95rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover:not(:disabled) {
          background-color: #1d4ed8;
          transform: translateY(-1px);
        }

        &:active:not(:disabled) {
          transform: translateY(0);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        &.success {
          background-color: #10b981;
        }

        &.error {
          background-color: #ef4444;
        }
      }
    }

    .success-message {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      padding: 1rem;
      background-color: #f0fdf4;
      border-radius: 12px;
      color: #059669;
      animation: fadeInUp 0.4s ease;

      .success-icon {
        animation: successPop 0.4s ease;
      }

      p {
        font-size: 0.95rem;
        font-weight: 500;
      }
    }
  }
}

.loading-spinner {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyfr;
