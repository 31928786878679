.likebook-list {
    width: 100%;
    margin-bottom: 50px;
    background-color: transparent !important; // 최 상위로 올려서 배경 투명하게 만들기

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px; //하단여백
        width: 100%;

        h1 {
            font-size: 1.5rem;
            color: inherit; // 부모 요소로 부터의 글자색 상속
        }

        select {
            padding: 5px 10px; //내부 여백
            font-size: 1rem;
            border: 1px solid #ccc; //테두리 설정
            border-radius: 4px; // 테두리 모서리 둥글게
            background-color: inherit; // 부모 요소로 부터의 색 상속
            color: inherit; // 부모 요소로 부터의 색 상속
            cursor: pointer; //마우스 올리면 커서가 포인터 모양으로 변경
//Themestore.jsx_secondary 가 셀렉트 상자랑 다른 상자 색 결정
            &:focus {
                outline: none; //select 태그가 포커스를 받을 때 아웃라인제거
                border-color: #ffffff;
            }
        }
    }

    table {
        width: 100%;
        color: inherit;
        border-collapse: collapse; //테이블 경계선 하나로 합침

        thead {
            background-color: #f1f1f1;

            th {
                padding: 10px;
                font-size: 1rem;
                color: inherit; // 부모 요소로 부터의 색 상속
                text-align: left; //텍스트 왼쪽 정렬
                border-bottom: 2px solid #ddd; // 하단 경계선
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: #f9f9f9; //짝수행의 배경색
                }

                &:hover {
                    background-color: #f1f1f1; //행에 마우스 올렸을 때 배경색 변경
                }

                td {
                    padding: 10px;
                    font-size: 1rem;
                    color: inherit; // 부모 요소로 부터의 색 상속
                    border-bottom: 1px solid #ddd;
                }
            }
        }
    }
}
