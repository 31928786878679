html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--main-bg-color);
  color: var(--text-color);

  &.spring {
    --main-bg-color: #ffe4e1;
    --text-color: #000;
  }

  &.summer {
    --main-bg-color: #082747;
    --text-color: #fff;
  }

  &.autumn {
    --main-bg-color: #ffdead;
    --text-color: #000;
  }

  &.winter {
    --main-bg-color: #1c1c1c;
    --text-color: #fff;
  }
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.theme-toggles {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 1rem;
  right: 2rem;

  .toggle-button {
    z-index: 100;
    cursor: pointer;
  }
}

.season-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}
