.email-confirmation-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .confirmation-card {
        max-width: 480px;
        width: 90%;
        padding: 40px;
        border-radius: 20px;
        text-align: center;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
        animation: slideUp 0.5s ease;

        .icon-container {
            margin-bottom: 24px;
            animation: iconPop 0.6s cubic-bezier(0.16, 1, 0.3, 1);

            .success-icon {
                color: #10b981;
            }

            .error-icon {
                color: #ef4444;
            }
        }

        h1 {
            font-size: 28px;
            margin-bottom: 16px;
        }

        p {
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 8px;
            opacity: 0.9;
        }

        .redirect-text {
            font-size: 14px;
            opacity: 0.7;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .error-detail {
            color: #ef4444;
            font-size: 14px;
            margin-top: 12px;
        }

        button {
            padding: 12px 24px;
            border: none;
            border-radius: 12px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            display: inline-flex;
            align-items: center;
            gap: 8px;

            &:hover {
                transform: translateY(-2px);
                filter: brightness(1.1);
            }

            &:active {
                transform: translateY(0);
            }
        }

        .button-group {
            display: flex;
            gap: 12px;
            justify-content: center;
            margin-top: 24px;

            button {
                flex: 1;
                max-width: 180px;
                justify-content: center;
            }
        }

        &.success {
            border: 1px solid rgba(16, 185, 129, 0.2);
        }

        &.error {
            border: 1px solid rgba(239, 68, 68, 0.2);
        }
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes iconPop {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
