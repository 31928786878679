
.comment-box {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 50px;
  
  h2 {
    margin-bottom: 10px;
  }

  textarea {
    width: 95%;
    color: inherit;
    height: 50px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none; /* 사용자가 크기를 조절하지 못하도록 설정합니다. */
    scrollbar-width: none; /* 스크롤바 안보임 */
  }
  
  button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
    &:hover {
      background-color: #eceaea !important;
    }
  }
}

.comments {
  margin-bottom: 20px;
}

.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  
  p {
    font-size: 14px;
    line-height: 1.4;
  }
  
  n{
    margin-right: 10px;
  }

  button {
    padding: 5px 10px;
    color: inherit;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
    &:hover {
      background-color: #f6f5f5 !important;
    }
  }
}

.comment-avatar {
  width: 55px;
  height: 50px;
  min-width: 50px; /* 추가 */
  min-height: 50px; /* 추가 */
  background-color: inherit;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: inherit;
  margin-right: 10px;
}

.comment-content{
  textarea {
    width: 95%;
    height: 70px;
    color: inherit;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none; /* 사용자가 크기를 조절하지 못하도록 설정합니다. */
    scrollbar-width: none; /* 스크롤바 안보임 */
  }
}