.mainPage {
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .listHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h1 {
            font-size: 3rem;
            color: inherit;
        }

        .headerControls {
            display: flex;
            align-items: center;

            .mainPageSelect {
                padding: 10px 20px;
                font-size: 1rem;
                border: 1px solid #ccc;
                border-radius: 6px;
                background-color: inherit;
                color: inherit;
                cursor: pointer;
                margin-right: 10px;
                &:focus {
                    outline: none;
                    border-color: #ffffff;
                }
            }

            .mainPageButton {
                padding: 10px 20px;
                font-size: 1rem;
                border: 1px solid #ccc;
                border-radius: 6px;
                background-color: inherit;
                color: inherit;
                cursor: pointer;
                margin-right: 10px;
                &:focus {
                    outline: none;
                    border-color: #ffffff;
                }
            }
        }

        .mainPageButton {
            padding: 10px 20px;
            font-size: 1rem;
            border: 1px solid #ccc;
            border-radius: 6px;
            background-color: inherit;
            color: inherit;
            cursor: pointer;
            margin-right: 10px;
            &:focus {
                outline: none;
                border-color: #ffffff;
            }
        }
    }

    .cardList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 20px;

        button {
            border: none;
            background: none;
            font-size: 16px;
            padding: 10px;
            cursor: pointer;
            color: inherit;

            &:hover:not(.active) {
                background-color: transparent;
            }
        }

        .active {
            background-color: #d4d4d4;
            color: white;
            border-radius: 5px;
        }
    }
}
