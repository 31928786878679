.themed-button {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 4rem;
    border-radius: 5px;
    background: transparent;
    border: none;
    font-family: "Poetsen One", sans-serif;
    margin-top: -5%;

    &:hover {
        transform: scale(1.3);
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: scale(0.95);
    }
}
