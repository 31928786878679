.SynopsysResult-letter {
    background: #f6f6f6;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 26px auto 0;

    min-height: 65vh;
    height: auto;
    max-height: 80vh;
    padding: 24px;
    position: relative;
    width: 80%;

    &::before,
    &::after {
        content: "";
        height: 98%;
        position: absolute;
        width: 100%;
    }

    &::before {
        background: #fafafa;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        left: -5px;
        top: 4px;
        transform: rotate(-2.5deg);
    }

    &::after {
        background: #f6f6f6;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        right: -3px;
        top: 1px;
        transform: rotate(1.4deg);
    }

    .editable-field {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        z-index: 1;

        .SynopsysResult-strong {
            position: relative;
            font-size: 1.5em;
            color: inherit;
        }

        .SynopsysResult-textarea {
            margin-top: 20px;
            position: relative;
            width: 95%;
            max-height: 60vh;
            padding: 10px;
            font-size: 1em;
            line-height: 1.2;
            border: none;
            border-radius: 0;
            resize: none;
            transition: border-color 0.2s;
            background: #f6f6f6;
            overflow: auto;
            line-height: 1.5;
            width: 95%;
            z-index: 20;
            &:focus {
                border-color: #495057;
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
                outline: none;
                background-color: #f9f9f9; /* Example focus state */
            }
        }
    }

    .button-group {
        display: flex;
        justify-content: space-between;
        width: 90%;
        position: absolute;
        bottom: 30px;
        z-index: 10;
    }

    .first-page .button-group {
        justify-content: flex-end;
    }

    button {
        background-color: transparent;
        border: none;
        font-weight: bold;
        color: var(--primary-color);

        cursor: pointer;
        font-size: 1.7em;
        margin-top: 5px;
        transition:
            background-color 0.3s,
            color 0.3s;
    }

    .tooltip {
        position: absolute;
        top: -35px;
        left: 50%;
        width: 500px;
        transform: translateX(-50%);
        padding: 10px 20px;
        background-color: white;
        color: black;
        border-radius: 3px;
        font-size: 0.5em;
        white-space: nowrap;
        border: 1px solid black;

        &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 5px;
            border-style: solid;
            border-color: white transparent transparent transparent;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
