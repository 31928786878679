.novel-generator {
    .synopsis-button-group {
        margin-bottom: 10px;

        .synopsis-h2 {
            margin-bottom: 5px;
        }

        .toggle-switch {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            margin-bottom: 10px;

            .toggle-icon {
                margin-left: auto;
            }
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .synopsis-button {
                position: relative;
                padding: 12px 20px;
                border: transparent;
                border-radius: 5px;
                cursor: pointer;
                transition:
                    background-color 0.3s ease,
                    color 0.3s ease;
            }
        }
    }

    .user-requests {
        margin-top: 10px;

        .synopsis-h2 {
            margin-bottom: 10px;
        }

        .synopsis-textarea {
            width: 100%;
            height: 100%;
            padding: 10px;
            font-family: var(--font-normal);
            border: 1px solid black;
            resize: none;

            border-color: #575757;
        }
    }

    .synopsis-button-row {
        margin-top: 10px;
        text-align: right;
        position: relative;

        .generate-button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            position: relative;

            &:hover {
                background-color: darken(#cccccc, 10%);
            }

            &:hover .tooltip {
                opacity: 1;
                visibility: visible;
            }
        }

        .tooltip {
            visibility: hidden;
            width: 300px;
            height: 50px;
            opacity: 0;
            background-color: #fff;
            color: black;
            text-align: center;
            border-radius: 5px;
            border: 1px solid black;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity 0.3s;

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #333 transparent transparent transparent;
            }
        }
    }
}

@keyframes checkmark {
    to {
        transform: translate(-50%, -50%) scale(1);
    }
}
