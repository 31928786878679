.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: transparent;
    color: black;
    text-align: center;
    overflow: hidden;

    h1 {
        font-size: 40vh;
        font-family: sans-serif;
        position: relative;
        background: white;
        color: black;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    h1:before {
        content: "";
        display: block;
        position: absolute;
        filter: blur(20px);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: screen;
        background-image: repeating-linear-gradient(-45deg, transparent, transparent 1em, #9e2053 1em, orange 50%),
            repeating-linear-gradient(45deg, #111626, #111626 1em, pink 1em, #571b3d 50%);
        background-size:
            3em 3em,
            2em 2em;
        animation: ani 8s linear infinite alternate;
    }
}

@keyframes ani {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 400% 0;
    }
}

.info {
    font-size: 20px;
    margin-top: 20px;
}

.home-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 20px;
    background-color: transparent;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
