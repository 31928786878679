// Loading.scss
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.3s ease-in-out;
}

.loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.loading-spinner {
    position: relative;
    width: 100px;
    height: 100px;
}

.spinner-ring {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: currentColor;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
        animation-delay: -0.45s;
    }
    &:nth-child(2) {
        animation-delay: -0.3s;
    }
    &:nth-child(3) {
        animation-delay: -0.15s;
    }
}

.loading-text {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.loading-letter {
    font-size: 1.5rem;
    font-weight: 600;
    animation: bounce 0.6s ease-in-out infinite;

    @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
            animation-delay: $i * 0.1s;
        }
    }
}

.loading-dots {
    display: flex;
    gap: 0.3rem;
    margin-left: 0.5rem;

    .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        animation: fade 1.4s ease-in-out infinite;

        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                animation-delay: $i * 0.2s;
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes fade {
    0%,
    100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
