.search-bar {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 16px;
    }
    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        .section {
            padding: 10px;
            border-bottom: 1px solid #eee;
            h3 {
                margin: 0 0 5px;
                font-size: 14px;
                color: #333;
            }
            button {
                display: block;
                width: 100%;
                text-align: left;
                background: none;
                border: none;
                padding: 5px;
                cursor: pointer;
                &:hover {
                    background: #f0f0f0;
                }
            }
        }
    }
}
