.modalOverlay {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  .modalContent {
    background-color: rgba(#2b443c, 0.85);
    border-radius: 3px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    z-index: 11;
    transition: all 0.3s ease-in-out;
    width: 720px;
    height: 220px;

    // 3200px 이하
    @media (max-width: 3201px) {
      width: 680px;
      height: 200px;
    }

    // 1920px 이하
    @media (max-width: 1920px) {
      width: 640px;
      height: 200px;
    }

    // 1200px 이하
    @media (max-width: 1200px) {
      width: 610px;
      height: 200px;
    }
    // 768px 이하
    @media (max-width: 768px) {
      width: 515px;
      height: 200px;
    }

    // 479px 이하
    @media (max-width: 479px) {
      width: 400px;
      height: 200px;
    }

    .user_options-container {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 60%;
      transform: translateX(-60%);
      width: calc(100% - 4rem);
      transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

      .user_options-text {
        color: azure;
        display: flex;
        justify-content: space-between;

        .user_options-unregistered,
        .user_options-registered {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          justify-content: left;

          button {
            background-color: transparent;
            padding: 10px 40px;
            border-radius: 3px;
            color: #fff;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
            border-color: white;
            border-width: 1px;
            &:hover {
              background-color: rgba(#222222, 0.85);
            }
          }
        }

        .user_options-unregistered,
        .user_options-registered {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          position: absolute;
          top: 55px;
          width: 40%;
          transition: transform 0.5s ease-in-out;
        }
        .user_options-unregistered {
          left: 0;
        }
        .user_options-registered {
          right: 5%;
        }
      }
    }
    .forms-container {
      position: absolute;
      transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

      .user_forms-login {
        margin-top: 1.6rem;
      }
      .user_forms-login,
      .user_forms-signup {
        width: 100%;
        padding: 2.3rem;
        box-sizing: border-box;
        transition: opacity 0.5s ease-in-out;

        .closeButton {
          position: absolute;
          color: black;
          top: 10px;
          right: 10px;
          border: none;
          background: none;
          font-size: 2rem;
          cursor: pointer;
          &:hover {
            color: darken(#fff, 10%);
          }
        }
        &.active {
          opacity: 1;
          visibility: visible;
          background-color: transparent;
        }
        &.inactive {
          opacity: 0;
          visibility: hidden;
          background-color: transparent;
          position: absolute;
        }
        .forms_title {
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          margin-bottom: 2rem;
          font-size: 1.5rem;
          color: #ff8177;
          text-align: center;
        }
        .forms_form {
          width: 100%;

          .forms_fieldset {
            border: none;
            margin: 0;
            padding: 0;

            .forms_field {
              margin-bottom: 1rem;
              background-color: transparent;
              padding: 0;
              position: relative;
              .forms_field-input {
                width: 90%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 3px;
                font-size: 1rem;
                color: #000;
                transition: border-color 0.3s ease;

                &:focus {
                  border-color: lighten(#ff8177, 10%);
                  outline: none;
                }
              }
            }
          }

          .forms_buttons {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .forms_buttons-forgot {
              background: none;
              border: none;
              color: #ff8177;
              cursor: pointer;
              padding: 5px;
              text-decoration: underline;
            }

            .forms_buttons-action {
              padding: 10px 20px;
              border-radius: 3px;
              background-color: #ff8177;
              color: #fff;
              font-family: "Montserrat", sans-serif;
              font-weight: 400;
              border: none;
              cursor: pointer;
              transition: background-color 0.3s ease;

              &:hover {
                background-color: darken(#ff8177, 10%);
              }
            }
          }
        }
      }
      &.show-login {
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 3px;
        z-index: 20;
        transform: translateX(120%);
        @media (max-width: 768px) {
          transform: translateX(110%);
        }
        @media (max-width: 468px) {
          transform: translateX(70%);
        }
      }

      &.show-signup {
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 3px;
        z-index: 20;
        transform: translateX(0%);
      }

      // 기본 크기
      &.show-login,
      &.show-signup {
        // 3201px 이상
        @media (min-width: 3200px) {
          width: 330px;
          height: 480px;
        }

        // 1920px 이상
        @media (max-width: 3199px) and (min-width: 1920px) {
          width: 310px;
          height: 460px;
        }

        // 1200px 이상
        @media (max-width: 1919px) and (min-width: 1200px) {
          width: 290px;
          height: 460px;
        }
        // 769px 이상
        @media (max-width: 1199px) and (min-width: 769px) {
          width: 280px;
          height: 420px;
        }

        // 480px 이상
        @media (max-width: 768px) and (min-width: 480px) {
          width: 240px;
          height: 400px;
        }

        // 480px 이하
        @media (max-width: 479px) {
          width: 200px;
          height: 400px;
        }
      }
    }
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  padding: 15px 20px;
  border-radius: 12px;
}

.Toastify__toast-container {
  top: 6em !important; // 상단 여백 조정

  .Toastify__toast {
    margin-bottom: 15px;
    background: #fff;
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    font-size: 0.9rem;
    padding: 15px 20px;
    border-radius: 12px;

    &--error {
      background-color: #ffebee;
      color: #d32f2f;
      border-left: 4px solid #d32f2f;
    }

    &--success {
      background-color: #e8f5e9;
      color: #2e7d32;
      border-left: 4px solid #2e7d32;
    }
  }
}

//애니메이션
@media (max-width: 900px) {
  .modalContent {
    width: 80%;
  }

  .forms-container.show-login,
  .forms-container.show-signup {
    transform: none;
    flex-direction: column;
  }
}
