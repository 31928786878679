.side-layout {
    display: flex;
    height: 100vh;
    background-color: transparent !important;
    z-index: 100;

    .menu-toggle {
        position: fixed;
        top: 20px;
        left: 20px;
        font-size: 1.5rem;
        cursor: pointer;
        z-index: 10;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 60px; /* 기본 너비 */
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 60px;
        transition: width 0.3s ease-in-out;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        z-index: 20; /* 메뉴 아이콘보다 위에 위치 */

        &.open {
            width: 240px; /* 열렸을 때 너비 */
        }

        .menu-item {
            width: 200px;
            display: flex;
            align-items: center;
            padding: 15px 20px;
            cursor: pointer;
            transition:
                background-color 0.3s ease,
                border-color 0.3s ease;

            &:hover {
                background-color: rgba(255, 255, 255, 0.5);
            }

            .menu-icon {
                font-size: 1.5rem;
                color: inherit; /* 아이콘 색상 상속 */
            }
            .menu-text {
                margin-left: 10px; /* 아이콘과 텍스트 사이 간격 */

                white-space: nowrap;
                transition: opacity 0.3s ease;
            }

            &.visible .menu-text {
                opacity: 1; /* 열렸을 때 텍스트 보임 */
            }
        }
        .menu-item-bottom {
            margin-top: auto; /* 하단으로 밀기 */
        }
    }

    .content {
        margin-left: 60px;
        padding: 20px;
        flex-grow: 1;
        transition: margin-left 0.3s ease-in-out;

        .book-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .book-item {
                height: 20px;
                background-color: #ccc;
                border-radius: 5px;

                &:nth-child(odd) {
                    width: 80%;
                }

                &:nth-child(even) {
                    width: 60%;
                }
            }
        }
    }
}
