.theme-mode-toggle {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);

    .season-icon {
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        border: none;
        border-radius: 0.5rem;
        background: rgba(255, 255, 255, 0.2);
        color: white;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 1.2rem;
            height: 1.2rem;
            transition: transform 0.3s ease;
        }

        &:hover:not(.disabled) {
            background: #ffbb52;

            svg {
                transform: scale(1.6);
            }
        }

        &.active {
            background: #ffbb52;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

            svg {
                transform: scale(1.2);
            }
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }

    // 스크린 리더를 위한 숨김 텍스트
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
}
