.homePage {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        position: absolute;
        bottom: 15%;
        z-index: 10;
        text-align: center;
        width: 260px;
        height: 435px;
        padding: 40px 60px;
        border-radius: 10px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(1px);

        h1 {
            font-size: 2.5rem;
            letter-spacing: 2px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
    }

    .subtitle {
        font-size: 1.2rem;
        white-space: pre-wrap;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        object-fit: cover;
        transition: opacity 0.5s ease-in-out;
        &.loading {
            filter: blur(10px);
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 1s ease-in-out;
        }

        .current {
            opacity: 1;
            z-index: 1;
        }

        .next {
            opacity: 0;
            z-index: 2;
        }

        .spread-in {
            animation: spreadIn 2s forwards;
        }
    }

    @keyframes spreadIn {
        0% {
            clip-path: circle(0% at 50% 50%);
            opacity: 1;
        }
        50% {
            clip-path: circle(75% at 50% 50%);
            opacity: 1;
        }
        100% {
            clip-path: circle(150% at 50% 50%);
            opacity: 1;
        }
    }

    .TeamText {
        position: absolute;
        bottom: 10px;
        right: 20px;
        font-family: "Rock Salt", cursive;
        letter-spacing: 2px;
    }
}
