.scroll-container {
    overflow: auto;
    height: 100vh;
    width: 100vw;
    scroll-snap-type: y mandatory;
    position: relative;
    scrollbar-width: none;
    .section {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        scroll-snap-align: start;
    }
}
