.container {
  text-align: center;
  padding: 2rem;
  padding-bottom: 10vh;
  height: 100%; // 화면 전체 높이 설정
  overflow: auto; //스크롤 활성화
  scrollbar-width: none; /* 스크롤바 안보임 */
}

.title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.cardlist {
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  display: grid; // 카드 열 4개로 고정
  grid-template-columns: repeat(4, 12.5rem);
}

.card {
  width: 200px;
  height: 300px;
  gap: 2rem;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border-color: #fff;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;
  }

  &:hover::before {
    background: rgba(0, 0, 0, 0.4);
  }

  .card-header {
    position: absolute;
    bottom: 1rem;
    width: 170px;
    background: transparent;
    color: #fff;
    padding: 1rem;
    text-align: left;
    transition: transform 0.3s ease;

    h1 {
      margin: 0;
      font-size: 1.5rem;
    }
  }

  .card-content {
    display: none;
    color: #fff;
    padding: 1rem;
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    z-index: 2;
    max-height: 6rem; // 2줄 높이
    overflow: hidden;

    p {
      margin: 0;
    }
  }

  &:hover .card-header {
    transform: translateY(-50%);
  }

  &:hover .card-content {
    display: block;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 50px;

  button {
    border: none;
    background: none;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    color: inherit;

    &:hover:not(.active) {
      background-color: transparent; // 배경색을 투명으로 설정
    }
  }

  .active {
    background-color: #d4d4d4;
    color: white;
    border-radius: 5px;
  }
}
