.profile {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  height: 100vh; /* 높이를 뷰포트 높이에 맞추어 설정 */
  overflow-y: auto; /* 스크롤 활성화 */
  scrollbar-width: none; /* 스크롤바 숨김 */

  .header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      color: inherit;
    }
  }

  .content {
    display: flex;
    max-width: 800px;
    justify-content: space-between;
    margin-bottom: 30px;

    .picture-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
      margin-left: 130px;

      .profile-picture-box {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 30px;

        .picture {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      button {
        margin-right: 10px;
        padding: 5px 10px;
        color: inherit;
        background-color: white;
        border: 1px solid;
        border-color: inherit;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f9f9f9;
        }
      }
    }

    .info {
      flex: 1;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: relative;

      .info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
      }

      label {
        font-weight: bold;
        margin-right: 10px;
        padding: 10px;
      }

      span {
        color: #555;
      }

      .edit-account {
        position: absolute;
        bottom: 10px;
        left: 10px;
        margin-right: 10px;
        padding: 5px 10px;
        color: inherit;
        background-color: white;
        border: 1px solid;
        border-color: inherit;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f9f9f9;
        }
      }

      .change-password {
        position: absolute;
        bottom: 10px;
        left: 130px;
        margin-right: 10px;
        padding: 5px 10px;
        color: inherit;
        background-color: white;
        border: 1px solid;
        border-color: inherit;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f9f9f9;
        }
      }

      .delete-account {
        position: absolute;
        bottom: 10px;
        right: 10px;
        margin-right: 10px;
        padding: 5px 10px;
        color: inherit;
        background-color: white;
        border: 1px solid;
        border-color: inherit;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column; /* 수직으로 요소를 배치합니다. */
    justify-content: space-between;
    flex: 1;
    margin-bottom: 20px;
    margin-right: 20px; /* 요소 사이의 간격을 조절합니다. */

    h2 {
      font-size: 1.5rem;
      color: inherit;
      margin-bottom: 10px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 50px;
      padding: 0;

      li {
        background-color: #fff;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 5px;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}
